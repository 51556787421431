import { default as NextLink } from 'next/link';
import { ComponentProps } from 'react';

type Props = Omit<ComponentProps<typeof NextLink>, 'className'>;

const Link: React.FC<Props> = (props) => {
  return <NextLink className="text-base font-bold text-primary underline" {...props} />;
};

export default Link;
