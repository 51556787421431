import { FlexpaApiErrors } from '../api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetcher<DataType = any>([url, options]: [
  RequestInfo | URL,
  RequestInit | undefined
]): Promise<DataType> {
  const res = await fetch(url, options);

  if (res.status === 204) return {} as DataType;

  const jsonBody = await res.json();

  if (!res.ok) {
    const errorMessage = 'message' in jsonBody ? jsonBody.message : `${res.status}: ${jsonBody}`;

    switch (res.status) {
      case 401:
        throw new FlexpaApiErrors.UnauthorizedError(errorMessage);
      case 404:
        throw new FlexpaApiErrors.NotFoundError(errorMessage);
      case 403:
        throw new FlexpaApiErrors.ForbiddenError(errorMessage);
      case 409:
        throw new FlexpaApiErrors.ConflictError(errorMessage);
      default:
        throw new Error(errorMessage);
    }
  }

  return jsonBody;
}
