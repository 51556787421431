import React from 'react';
import NavBar from './NavBar';
import { SidebarNav } from './SidebarNav';
import { classNames } from '../../lib/classNames';

const Layout: React.FC<{ showSidebar: boolean; children: React.ReactNode }> = ({ showSidebar, children }) => {
  return (
    <div className="min-h-screen w-full min-w-fit">
      <header>
        <NavBar />
      </header>
      <main className="flex size-full min-w-fit justify-center">
        {showSidebar && <SidebarNav />}
        <div className={classNames('h-full', showSidebar ? 'flex-1 p-6' : 'mt-12')}>{children}</div>
      </main>
    </div>
  );
};

export default Layout;
