import { Spacing, spacingToTWClass } from './spacing';

interface TextFormFieldProps {
  label?: string;
  text?: string;
  spacing?: Partial<Spacing>;
}

const TextFormField: React.FC<TextFormFieldProps> = ({ label, text, spacing }) => {
  const margin = spacing && spacingToTWClass(spacing);

  return (
    <div className={`relative flex flex-col ${label && 'pb-6'} ${margin}`}>
      <label className="text-base font-medium">{label}</label>
      <p className="text-base" style={{ whiteSpace: 'pre-line' }}>
        {text}
      </p>
    </div>
  );
};

export default TextFormField;
