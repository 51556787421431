import { useId } from 'react';
import { ErrorMessage } from './Typography';

interface TextAreaFieldProps {
  label?: string;
  error?: string | undefined;
  description?: string;
}

const TextAreaField: React.FC<Omit<React.ComponentProps<'textarea'>, 'id' | 'className'> & TextAreaFieldProps> = ({
  label,
  error,
  description,
  ...inputProps
}) => {
  const id = useId();

  return (
    <div className="flex flex-col gap-[2px] text-sm font-normal">
      <label className="text-base font-medium" htmlFor={id}>
        {label}
      </label>
      {description && (
        <label className="text-sm text-black" htmlFor={id}>
          {description}
        </label>
      )}
      <textarea
        {...inputProps}
        className="flex w-full items-start justify-between self-stretch rounded border border-flexpa-gray-primary-20 bg-white px-3 py-4 placeholder:text-flexpa-gray-primary-40 disabled:border-flexpa-gray-primary-10 disabled:bg-flexpa-gray-primary-05 disabled:text-flexpa-gray-primary-40"
        id={id}
      />
      {error && (
        <div className="absolute bottom-0 left-0 w-max">
          <ErrorMessage>{error}</ErrorMessage>
        </div>
      )}
    </div>
  );
};

export default TextAreaField;
