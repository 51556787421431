import { Spacing, spacingToTWClass } from './spacing';

interface CheckboxProps {
  label: string | React.ReactNode;
  spacing?: Partial<Spacing>;
}

const Checkbox: React.FC<Omit<React.ComponentProps<'input'>, 'id' | 'className' | 'type'> & CheckboxProps> = ({
  label,
  spacing,
  ...inputProps
}) => {
  const margin = spacing && spacingToTWClass(spacing);
  return (
    <label className={`flex items-start ${margin}`}>
      <input type="checkbox" className="mr-2 mt-0.5 size-5 flex-none" {...inputProps} />
      {label}
    </label>
  );
};

export default Checkbox;
