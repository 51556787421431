import { Button, TextFormField } from '../designSystem';
import React from 'react';

interface FormData {
  formStatus: 'invalid' | 'valid' | 'submitting';
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  error?: Error | null | undefined;
}

interface ReadAndEditToggableFormProps {
  label: string;
  mode: 'read' | 'edit';
  onModeToggle: () => void;
  formData: FormData;
}

const ReadAndEditToggableForm = ({ label, mode, onModeToggle, formData }: ReadAndEditToggableFormProps) => {
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.formStatus === 'valid') {
      formData.onSubmit();
    }
  };

  return (
    <div>
      <form className="flex w-56 flex-col" onSubmit={handleSubmit}>
        <TextFormField
          label={label}
          onChange={(e) => {
            formData.onChange(e.currentTarget.value);
            if (mode === 'read') {
              onModeToggle();
            }
          }}
          value={formData.value}
          error={formData.error?.message}
        />
        <div className="flex h-14">
          {mode === 'edit' && (
            <Button type="submit" disabled={formData.formStatus !== 'valid'}>
              Save
            </Button>
          )}
          {mode === 'edit' && (
            <div className="ml-2 mt-2">
              <Button variant="text" onClick={onModeToggle} type="button">
                Cancel
              </Button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default ReadAndEditToggableForm;
