import React from 'react';
import { classNames } from '../../lib/classNames';
import { SubtextBold } from './Typography';

export interface BadgeProps extends Omit<React.ComponentProps<'span'>, 'className'> {
  variant?: 'neutral' | 'error' | 'in-progress' | 'success' | 'informational';
  children: React.ReactNode;
}

export default function Badge({ variant = 'neutral', children, ...rest }: BadgeProps) {
  return (
    <span
      {...rest}
      className={classNames(
        'flex h-fit items-center rounded p-1 text-sm font-medium text-black',
        variant === 'neutral' && 'bg-flexpa-gray-primary-10',
        variant === 'error' && 'bg-flexpa-red-error-25',
        variant === 'in-progress' && 'bg-flexpa-yellow-warning-25',
        variant === 'success' && 'bg-flexpa-green-accent-50',
        variant === 'informational' && 'bg-flexpa-green-tertiary-100'
      )}
    >
      <SubtextBold>{children}</SubtextBold>
    </span>
  );
}
