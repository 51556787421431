import { spacingToTWClass, Spacing } from './spacing';

export const Title1: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <h1 className="text-4xl font-bold leading-10 tracking-wide">{children}</h1>;
};

export const Title2: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <h2 className="text-3xl font-semibold leading-9 tracking-wide text-black">{children}</h2>;
};

export const Title3: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <h3 className="text-2xl font-semibold leading-7 tracking-wide text-black">{children}</h3>;
};

export function Eyebrow({ children }: { children: React.ReactNode }) {
  return <h4 className="font-mono text-sm font-bold uppercase leading-4 tracking-wide">{children}</h4>;
}

export function Subtext({ children }: { children: React.ReactNode }) {
  return <h4 className="text-2xs font-normal">{children}</h4>;
}

export function SubtextBold({ children }: { children: React.ReactNode }) {
  return <h4 className="text-2xs font-semibold leading-3 tracking-wide">{children}</h4>;
}

type TextColor = 'danger' | 'neutral';

interface ParagraphProps {
  children: React.ReactNode;
  spacing?: Partial<Spacing>;
  textColor?: TextColor;
  whitespace?: 'nowrap';
}

function textColorToTWClass(textColor: TextColor) {
  switch (textColor) {
    case 'danger':
      return 'text-danger';
    case 'neutral':
      // TODO: decide on a neutral colour, at the moment fallback to black
      return 'text-black';
  }
}

export const Paragraph: React.FC<ParagraphProps> = ({ children, spacing, textColor = 'neutral', whitespace }) => {
  const margin = spacing && spacingToTWClass(spacing);
  const color = textColorToTWClass(textColor);
  const wrap = whitespace === 'nowrap' ? 'whitespace-nowrap' : '';

  return <p className={`text-sm ${margin} ${color} ${wrap}`}>{children}</p>;
};

export function Body1({ children }: { children: React.ReactNode }) {
  return <p className="text-lg font-normal">{children}</p>;
}

export function Body1Bold({ children }: { children: React.ReactNode }) {
  return <p className="text-lg font-semibold tracking-wide">{children}</p>;
}

export function Body2({ children }: { children: React.ReactNode }) {
  return <p className="text-sm font-normal">{children}</p>;
}

export function Body2Bold({ children }: { children: React.ReactNode }) {
  return <p className="text-sm font-semibold leading-5 tracking-wide">{children}</p>;
}

export const ErrorMessage: React.FC<Omit<ParagraphProps, 'textColor'>> = (props) => {
  return <Paragraph textColor="danger" {...props} />;
};
