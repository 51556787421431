import { Spacing, spacingToTWClass } from './spacing';

interface RadioButtonProps {
  label: string;
  spacing?: Partial<Spacing>;
}

const RadioButton: React.FC<Omit<React.ComponentProps<'input'>, 'id' | 'className' | 'type'> & RadioButtonProps> = ({
  label,
  spacing,
  ...inputProps
}) => {
  const margin = spacing && spacingToTWClass(spacing);
  return (
    <label className={`flex items-start ${margin}`}>
      <input type="radio" className="mr-2 mt-0.5 size-5 flex-none" {...inputProps} />
      {label}
    </label>
  );
};

export default RadioButton;
