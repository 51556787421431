const spaceMapping = {
  y: { xs: 'my-2', sm: 'my-3', base: 'my-6', lg: 'my-10' },
  x: { xs: 'mx-2', sm: 'mx-3', base: 'mx-6', lg: 'mx-10' },
  t: { xs: 'mt-2', sm: 'mt-3', base: 'mt-6', lg: 'mt-10' },
  b: { xs: 'mb-2', sm: 'mb-3', base: 'mb-6', lg: 'mb-10' },
  l: { xs: 'ml-2', sm: 'ml-3', base: 'ml-6', lg: 'ml-10' },
  r: { xs: 'mr-2', sm: 'mr-3', base: 'mr-6', lg: 'mr-10' },
};

export type Spacing = { [Key in keyof typeof spaceMapping]: keyof typeof spaceMapping[Key] };

export function spacingToTWClass(spacing: Partial<Spacing>) {
  const directionAndMagnitudePairs = Object.entries(spacing) as [keyof Spacing, Spacing[keyof Spacing]][];
  return directionAndMagnitudePairs
    .map(([direction, magnitude]) => {
      return spaceMapping[direction][magnitude];
    })
    .join(' ');
}
